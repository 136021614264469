import React from "react";
import { useZxing } from "react-zxing";

interface IQRScannerProps {
  onDecodeResult: (result: any) => void;
}

function QrScanner(props: IQRScannerProps) {
  const { ref } = useZxing({
    onDecodeResult(result) {
      props.onDecodeResult(result);
    },
  });

  return (
    <div className="qrscanner">
      <video ref={ref} className="video w-75 h-75" />
    </div>
  );
}

export default QrScanner;
