import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import RegistrationForm from "./registrationForm";

const VisitorRegistrationForm: React.FC = () => {
  return (
    <>
      <div className="login-form container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-7 col-sm-11 col-lg-7 m-5 bg-white">
            <div className="form_container p-2 rounded  ">
              <div className="container form-group  h-100 mx-auto my-3">
                <div className="nsp-logo" />
                <h1>WELCOME TO NSP</h1>
              </div>
              <RegistrationForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VisitorRegistrationForm;
