import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Camera from "../../widgets/Camera";
import "bootstrap-icons/font/bootstrap-icons.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  IVisitorDetails,
  registerNewVisitor,
} from "../../services/visitorRegistrationService";
import { ThankyouPage_Path } from "../Routes/routes";

const RegistrationForm: React.FC = () => {
  const [show, setShow] = useState(false);
  const [photo, setPhoto] = useState<string | null>();
  const handleClose = () => setShow(false);
  const modalShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [hostEmail, setHostEmail] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [visitpurpose, Setvisitpurpose] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [validated, setValidated] = useState(false);

  const registerVisitor = (formData: IVisitorDetails): void => {
    registerNewVisitor(formData) //actual api is being called
      .then((response) => {
        if (response.status === 201) {
          redirectToThankyou();
        }
      })
      .catch((err: any) => console.log(err, "err"));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      let formData = {
        name: name,
        email: email,
        phone: phonenumber,
        visitPurpose: visitpurpose,
        hostEmail: hostEmail,
        photo: photo?.split(",")[1]!,
        aadhar: aadhar,
      };
      if (photo === undefined) {
        alert("Please add the photo");
      } else {
        registerVisitor(formData);
      }
    }

    setValidated(true);
  };
  const redirectToThankyou = () => {
    window.location.href = ThankyouPage_Path;
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom01"
        >
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Name"
            pattern="[A-Za-z ]+"
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please provide your name.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom02"
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please provide your Email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustomUsername"
        >
          <Form.Label>Phone Number</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Phone Number"
              aria-describedby="inputGroupPrepend"
              pattern="[6-9][0-9]{9}"
              required
              onChange={(e) => setPhonenumber(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a Valid Phone Number
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom03"
        >
          <Form.Label>Purpose Of Visit</Form.Label>
          <Form.Control
            className="Purpose-Of-Visit"
            type="text"
            placeholder="Purpose Of Visit"
            required
            onChange={(e) => Setvisitpurpose(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide purpose of your visit.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom02"
        >
          <Form.Label>Host Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Host email"
            pattern="[a-zA-Z0-9._%+-]+@in\.nspglobaltech\.com"
            onChange={(e) => setHostEmail(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please provide valid Host Email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom04"
        >
          <Form.Label>Aadhar Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Aadhar Number"
            max={12}
            onChange={(e) => setAadhar(e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          md="7"
          sm="11"
          lg="8"
          controlId="validationCustom05"
        >
          <div className="form-group h-100 mx-auto my-3">
            <label htmlFor="exampleFormControlFile1">Choose Your Photo</label>

            <div>
              {photo === null || photo === undefined ? (
                <Button variant="success" className="my-3" onClick={modalShow}>
                  Click for photo
                  <i className="bi bi-camera mx-2"></i>
                </Button>
              ) : (
                <div className="d-flex align-items-center">
                  <img className="w-50" src={photo} alt="" />
                  <button
                    className="btn btn-success mx-3"
                    onClick={() => {
                      setPhoto(null);
                      modalShow();
                    }}
                  >
                    Retake
                  </button>
                </div>
              )}

              <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                className="capture-modal"
              >
                <Modal.Header
                  closeButton
                  className="modal-logo mx-3 mt-3"
                ></Modal.Header>
                <Modal.Body>
                  <Camera photo={photo} setphoto={setPhoto}></Camera>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </Form.Group>
      </Row>

      <Button type="submit">Submit form</Button>
    </Form>
  );
};
export default RegistrationForm;
