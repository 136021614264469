import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { SecurityLoginForm_Path } from "../Routes/routes";
import {
  IVisitDetails,
  getVisitorDetails,
} from "../../services/VisitorRequestService";
import { Button, Col, Container, Row } from "react-bootstrap";
import { registerNewVisit } from "../../services/checkVisitService";
import QrScanner from "../../widgets/QRScanner";

export const QRScanner = () => {
  const [isExistingVisit, setIsExistingVisit] = useState(false);
  const [isScanning, setIsScanning] = useState(true);
  const [visitDetails, setVisitDetails] = useState<IVisitDetails>({
    id: 0,
    visitId: "",
    createdOn: "",
    scheduleVisitDate: "" || null,
    visitDate: "" || null,
    visitPurpose: "",
    hostEmail: "",
    hostResponse: "",
    hostResponseReason: "" || null,
    visitor: {
      id: 0,
      name: "",
      email: "",
      phone: "",
      photo: "",
      aadhar: "",
    },
  });

  const onDecodeResult = (result: any) => {
    if (isScanning) {
      setIsScanning(false);
      registerVisit(result.getText());
    }
  };

  const redirectToLogin = () => {
    window.location.href = SecurityLoginForm_Path;
  };

  const registerVisit = (visitId: string) => {
    registerNewVisit({ visitId })
      .then(() => {
        fetchVisitorDetails(visitId);
      })
      .catch((error) => {
        if (error.response.status === 409) setIsExistingVisit(true);
      });
  };

  const fetchVisitorDetails = (vId: string) => {
    getVisitorDetails(vId)
      .then((resp) => {
        setVisitDetails(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("role") !== "Security") {
      redirectToLogin();
    }
  }, []);

  return (
    <>
      <div
        className="qr-scanner text-center"
        style={{ height: "104vh", backgroundColor: "lightgray" }}
      >
        {isScanning && <QrScanner onDecodeResult={onDecodeResult} />}

        {!isScanning && (
          <Container fluid>
            <div className="nsp-logo" />
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <div className="id-card d-sm-flex d-block text-center">
                  {!isExistingVisit && (
                    <>
                      <Col sm={6}>
                        <img
                          src={`data:image/jpeg;base64,${visitDetails?.visitor?.photo}`}
                          alt="VisitorPhoto"
                          className="visitor-photo"
                        />
                      </Col>
                      <Col sm={6} className="text-md-left">
                        <h2 className="mb-3">
                          Visitor's Name: {visitDetails?.visitor?.name}
                        </h2>
                        <p className="text-muted">
                          Purpose: {visitDetails?.visitPurpose}
                        </p>
                        <p className="text-muted">
                          Email: {visitDetails?.visitor?.email}
                        </p>
                        <p className="text-muted">
                          Phone: {visitDetails?.visitor?.phone}
                        </p>
                      </Col>
                    </>
                  )}

                  {isExistingVisit && (
                    <h4>
                      This visit is already registered, please make a new
                      request
                    </h4>
                  )}
                </div>
              </Col>
            </Row>
            <Button
              variant="outline-primary"
              className="px-4"
              onClick={() => setIsScanning(true)}
            >
              OK
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a .5 .5 0 0 1 .708 0L8 7.293l2.646-2.647a.5 .5 0 0 1 .708 .708L8.707 8l2.647 2.646a.5 .5 0 0 1-.708 .708L8 8.707l-2.646 2.647a .5 .5 0 0 1-.708-.708L7.293 8 4.646 5.354a .5 .5 0 0 1 0-.708" />
              </svg>
            </Button>
          </Container>
        )}
      </div>
    </>
  );
};

export default QRScanner;
