import { type AxiosResponse } from "axios";
import { Post } from "../utils/apiService";

const hostResponseEndpoint = "/visit/hostResponse";

export interface IHostResponseDetails {
  visitId: string;
  hostResponse: string;
  hostResponseReason?: string;
}

export const registerHostResponse = async (
  data: IHostResponseDetails
): Promise<AxiosResponse<any, IHostResponseDetails>> =>
  await Post<any, IHostResponseDetails>(`${hostResponseEndpoint}`, data);
