import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

const SuccessPage: React.FC = () => {
  return (
    <>
      <div className="login-form container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-7 col-sm-11 col-lg-7 m-5 bg-white">
            <div className="form_container p-2 rounded mt-5 ">
              <div className="container form-group  h-100 mx-auto my-3">
                <div className="nsp-logo" />
                <h1>
                  Thank Your For Your Response
                  <i className="bi bi-check2-circle text-success"></i>
                </h1>
                <h3 className="mt-5">
                  Visitor is get notified about your response.
                  <i className="text-warning bi bi-emoji-sunglasses"></i>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessPage;
