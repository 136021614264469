import { AxiosResponse } from 'axios';
import { Post } from '../utils/apiService';

const visitorRegistrationEndpoint = "/auth/login";

export interface ISecurityDetails {
    username: string,
    password: string,
  }

  export const LoginValidSecurity = async (
    data: ISecurityDetails
  ): Promise<AxiosResponse<any, ISecurityDetails>> =>
    await Post<any, ISecurityDetails>(`${visitorRegistrationEndpoint}`, data);
