import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import {
  LoginValidSecurity,
  ISecurityDetails,
} from "../../services/LoginSecurityService";
import { qrScanner_Path } from "../Routes/routes";

const SecurityLoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const redirectToQRScanner = () => {
    window.location.href = qrScanner_Path;
  };

  const LoginSecurity = (SecurityData: ISecurityDetails): void => {
    LoginValidSecurity(SecurityData)
      .then((response) => {
        if (response.status === 201) {
          localStorage.setItem("role", response.data.response.role);
          redirectToQRScanner();
        }
      })
      .catch((err: any) => console.log(err, "err"));
  };

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: any;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      let SecurityData = {
        username: username,
        password: password,
      };
      if (username === username && password === password) {
        setValidated(true);
        LoginSecurity(SecurityData);
      } else {
        setLoginError(true);
      }
    }
  };

  return (
    <div className="login-form container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-7 col-sm-11 col-lg-7 m-5 bg-white">
          <div className="form_container p-2 rounded  ">
            <div className="container form-group  h-100 mx-auto my-3">
              <div className="nsp-logo" />

              <div className="container">
                <h1>Security Login</h1>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group
                    as={Col}
                    md="7"
                    sm="11"
                    lg="8"
                    controlId="formBasicUsername"
                  >
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter username"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a username.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="7"
                    sm="11"
                    lg="8"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a password.
                    </Form.Control.Feedback>
                  </Form.Group>

                  {loginError && (
                    <Form.Text className="text-danger">
                      Incorrect username or password.
                    </Form.Text>
                  )}
                  <br />

                  <Button className="mt-4" variant="primary" type="submit">
                    Login
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityLoginForm;
