import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  IVisitDetails,
  getVisitorDetails,
} from "../../services/VisitorRequestService";
import { registerHostResponse } from "../../services/hostResponseService";
import { SuccessPage_Path } from "../Routes/routes";

export default function VisitorRequestPage() {
  const [visitDetails, setVisitDetails] = useState<IVisitDetails>({
    id: 0,
    visitId: "",
    createdOn: "",
    scheduleVisitDate: "" || null,
    visitDate: "" || null,
    visitPurpose: "",
    hostEmail: "",
    hostResponse: "",
    hostResponseReason: "" || null,
    visitor: {
      id: 0,
      name: "",
      email: "",
      phone: "",
      photo: "",
      aadhar: "",
    },
  });
  const [visitId, setVisitId] = useState("");
  const [isRequestRejected, setIsRequestRejected] = useState(false);
  const [reason, setReason] = useState("");
  const [requestStatus, setRequestStatus] = useState("pending");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const visitId = urlParams.get("visitId");
    if (visitId) {
      setVisitId(visitId);
      getVisitorDetails(visitId!)
        .then((resp) => {
          setVisitDetails(resp);
          setRequestStatus(resp.hostResponse);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, []);

  const redirectToSuccess = () => {
    window.location.href = SuccessPage_Path;
  };

  const handleResponse = (hostResponse: string): void => {
    setIsRequestRejected(false);
    registerHostResponse({ visitId, hostResponse, hostResponseReason: reason })
      .then((response) => {
        setRequestStatus(response.data.completeBody.hostResponse);
        if (response.status === 201) {
          redirectToSuccess();
        }
      })
      .catch((err: any) => console.log(err, "err"));
  };
  return (
    <Container fluid>
      <Row className=" justify-content-center">
        <Col lg={8} md={10}>
          <div className="id-card d-sm-flex d-block text-center">
            <Col sm={6}>
              <img
                src={`data:image/jpeg;base64,${visitDetails?.visitor?.photo}`}
                alt="VisitorPhoto"
                className="visitor-photo"
              />
            </Col>
            <Col sm={6} className="text-md-left">
              <h2 className="mb-3">
                Visitor's Name : {visitDetails?.visitor?.name}
              </h2>
              <p className="text-muted">
                Purpose: {visitDetails?.visitPurpose}
              </p>
              <p className="text-muted">
                Email: {visitDetails?.visitor?.email}
              </p>
              <p className="text-muted">
                Phone: {visitDetails?.visitor?.phone}
              </p>
            </Col>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={4} md={6} className="text-center">
          <h5>{`${visitDetails?.visitor?.name} wants to meet you!`}</h5>
          <div className="d-flex justify-content-between my-3">
            <Button
              variant="outline-danger"
              className="px-4"
              onClick={() => setIsRequestRejected(true)}
              disabled={requestStatus !== "pending"}
            >
              Decline
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </Button>
            <Button
              variant="outline-success"
              className="px-4"
              onClick={() => handleResponse("accepted")}
              disabled={requestStatus !== "pending"}
            >
              Accept
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check2"
                viewBox="0 0 16 16"
              >
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
              </svg>
            </Button>
          </div>
        </Col>
      </Row>

      {isRequestRejected && (
        <Row className="justify-content-center">
          <Col lg={4} md={6} className="text-center">
            <Form.Label>Reason for Decline</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />

            <Button
              variant="outline-success"
              className="mt-4 px-4"
              onClick={() => handleResponse("rejected")}
            >
              Submit
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}
