import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  IVisitDetails,
  getVisitorDetails,
} from "../../services/VisitorRequestService";
import QRCode from "react-qr-code";

export default function ViewHostResponsePage() {
  const [visitDetails, setVisitDetails] = useState<IVisitDetails>({
    id: 0,
    visitId: "",
    createdOn: "",
    scheduleVisitDate: "" || null,
    visitDate: "" || null,
    visitPurpose: "",
    hostEmail: "",
    hostResponse: "",
    hostResponseReason: "" || null,
    visitor: {
      id: 0,
      name: "",
      email: "",
      phone: "",
      photo: "",
      aadhar: "",
    },
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const visitId = urlParams.get("vid");

    getVisitorDetails(visitId!)
      .then((resp) => {
        setVisitDetails(resp);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <Container fluid>
      <Row className=" justify-content-center">
        <Col lg={8} md={10}>
          <div className="id-card d-sm-flex d-block flex-column justify-content-center">
            {visitDetails.hostResponse === "accepted" && (
              <>
                <div>
                  Your request has been Accepted, Please scan below QR code at
                  the security
                </div>
                <div className="mt-4">
                  <QRCode value={visitDetails.visitId} />
                </div>
              </>
            )}

            {visitDetails.hostResponse === "rejected" && (
              <p>{`Your request has been rejected due to the reason : ${visitDetails.hostResponseReason}`}</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
