import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RouteComponent from "./RouteComponent";
import { IRouteConfig, routesConfig } from "./pages/Routes/routes";
import React from "react";
function App() {
  return (
    <>
      <Router>
        <Routes>
          {routesConfig.map((route: IRouteConfig) => (
            <Route
              path={route.path}
              element={
                <RouteComponent
                  layout={route.layout}
                  component={route.component}
                />
              }
            />
            
          ))}
        </Routes>
      </Router>
    </>
  );
}

export default App;
