import { type AxiosResponse } from "axios";
import { Post } from "../utils/apiService";

const visitRegistrationEndpoint = "/visitor/checkVisit";

export interface IVisitDetails {
  visitId: string;
}

export const registerNewVisit = async (
  data: IVisitDetails
): Promise<AxiosResponse<any, IVisitDetails>> =>
  await Post<any, IVisitDetails>(`${visitRegistrationEndpoint}`, data);
