import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import "bootstrap-icons/font/bootstrap-icons.css";


const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
interface ICamera{
  photo: string| null | undefined
  setphoto: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

const videoConstraints = {
  width: 350,
  facingMode: FACING_MODE_USER,
};
const Camera = (props:ICamera) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  },[]);
  const capturePhoto = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current?.getScreenshot();
      // console.log(imageSrc.split(",")[1])    //this is base64 
      props.setphoto(imageSrc);
    }
  }, [webcamRef]);

  return (
    <>
      {props.photo ? <img src={props.photo} alt="screenShot" />:<Webcam 
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={{...videoConstraints,facingMode}}
        
      />}
    
    <div className="d-flex align-item-between">
    <button className="btn btn-primary mx-2" onClick={handleClick}>Switch camera</button>
      <button className="btn btn-success" onClick={capturePhoto}>
        Capture
        <i className="bi bi-check2 mx-2"></i>
      </button>
      <button className="btn btn-danger mx-2" onClick={() => props.setphoto(null)}>
        Reset
        <i className="bi bi-x-lg mx-2"></i>
      </button>
      </div>
{/* {photo && <img src={photo} alt="screenShot" />} */}
    </>
  );
};

export default Camera;
