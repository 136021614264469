import { Get } from "../utils/apiService";

export interface IVisitorDetails {
  id: number;
  name: string;
  email: string;
  phone: string;
  photo: string;
  aadhar: string;
}

export interface IVisitDetails {
  id: number;
  visitId: string;
  createdOn: string;
  scheduleVisitDate: string | null;
  visitDate: string | null;
  visitPurpose: string;
  hostEmail: string;
  hostResponse: string;
  hostResponseReason: string | null;
  visitor: IVisitorDetails;
}

interface IVisitDetailsResponse {
  data: IVisitDetails;
  status: number;
  statusText: string;
}

export const getVisitorDetails = async (visitsId: string) => {
  const response = await Get<IVisitDetailsResponse, string>(
    `/visitor/${visitsId}`
  );
  return response.data;
};
