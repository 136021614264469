import Thankyoupage from "../Thankyoupage/Thankyoupage";
import VisitorRegistrationForm from "../VisitorRegistrationForm/visitorRegistrationForm";
import visitorRequestPage from "../VisitorRequestPage/visitorRequest";
import { QRScanner } from "../QRScanner";
import SecurityLoginForm from "../SecurityLogin/SecurityLogin";
import SuccessPage from "../SuccessPage";
import ViewHostResponsePage from "../ViewHostResponse";

export const visitorRegistrationForm_Path = "/";
export const ThankyouPage_Path = "/thankyou";
export const SuccessPage_Path = "/success-page";
export const VisitorRequestPage_Path = "/visitorrequest";
export const qrScanner_Path = "/qrscanner";
export const SecurityLoginForm_Path = "/securitylogin";
export const hostResponse_Path = "/hs";

export interface IRouteConfig {
  path: string;
  component: React.ElementType;
  layout?: React.ElementType;
}
export const routesConfig: IRouteConfig[] = [
  {
    path: visitorRegistrationForm_Path,
    component: VisitorRegistrationForm,
  },
  {
    path: VisitorRequestPage_Path,
    component: visitorRequestPage,
  },
  {
    path: ThankyouPage_Path,
    component: Thankyoupage,
  },
  {
    path: qrScanner_Path,
    component: QRScanner,
  },
  {
    path: SecurityLoginForm_Path,
    component: SecurityLoginForm,
  },
  {
    path: SuccessPage_Path,
    component: SuccessPage,
  },
  {
    path: hostResponse_Path,
    component: ViewHostResponsePage,
  },
];
